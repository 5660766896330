import React, { Component } from "react"
import {
  FormGroup,
  Label,
  Col,
  Input,
  FormFeedback,
  FormText,
} from "reactstrap"

export default class InputOptions extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }
  render() {
    const {
      charact: {
        required,
        sizeSM,
        forL,
        id,
        label,
        icon,
        type,
        pattern,
        name,
        longQ,
        options,
      },
      value,
      lang,
    } = this.props
    return (
      <FormGroup row>
        <Label sm={sizeSM[0]} size="lg" for={forL} id={id}>
          <i className={`fas fa-${icon}`} />
          &nbsp;
          {label[lang]}
        </Label>
        <Col xs={12} sm={sizeSM[1]}>
          <Input
            onChange={this.props.getValue}
            className={type === "range" ? "slider_range" : "custom"}
            value={value}
            pattern={pattern ? pattern : null}
            id={id}
            type={type}
            // eslint-disable-next-line
            autoFocus
            required={required}
            name={name}
          >
            {options.map((el, i) => (
              <option value={el[lang]}>{el[lang]}</option>
            ))}
          </Input>
          <FormFeedback className="animated fadeIn" valid>
            Súper
          </FormFeedback>
          <FormFeedback>Ingresa 10 dígitos</FormFeedback>
          <FormText>{longQ}</FormText>
        </Col>
      </FormGroup>
    )
  }
}
