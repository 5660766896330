import React, { useState, useEffect } from "react"

export default function TimelineNode({
  info: { img, text, year },
  selYear,
  setText,
  setYear,
}) {
  const [hovered, setHovered] = useState(false)
  useEffect(() => {
    setText(text)
    setYear(year)
    if (year !== selYear) {
      setHovered(false)
    }
    // Update the document title using the browser API
    // eslint-disable-next-line
  }, [hovered])
  // eslint-disable-next-line
  useEffect(() => {
    if (year === selYear) {
      setHovered(true)
      setText(text)
    }
    // eslint-disable-next-line
  }, [])

  return (
    <div
      // eslint-disable-next-line
      onMouseEnter={() => setHovered(true)}
      // eslint-disable-next-line

      onFocus={() => setHovered(true)}
      // eslint-disable-next-line
      onBlur={() => setHovered(false)}
      // eslint-disable-next-line
      onMouseLeave={() => setHovered(false)}
      className="tl_node_target"
    >
      <div className={`${year === selYear ? "" : "pulse_hover"} tl_node`}>
        {year === selYear ? (
          <img className="tl_node_img animated fadeIn" src={img} alt="" />
        ) : (
          <span></span>
        )}
      </div>
    </div>
  )
}
