import React, { Component } from "react"
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  // CarouselCaption
} from "reactstrap"

export default class CarouselGallery extends Component {
  render() {
    const { activeIndex, items } = this.props
    const slides = items.map((item, i) => {
      return (
        <CarouselItem
          onExiting={this.props.onExiting}
          onExited={this.props.onExited}
          key={i}
        >
          <img className="w-100" src={item.img} alt={item.alt} />
          {/*<CarouselCaption captionHeader={item.caption} className="filtroo" />*/}
        </CarouselItem>
      )
    })
    return (
      <Carousel
        activeIndex={activeIndex}
        next={this.props.next}
        previous={this.props.previous}
      >
        <CarouselIndicators
          items={items}
          activeIndex={activeIndex}
          onClickHandler={this.props.goToIndex}
        />
        {slides}
        <CarouselControl
          direction="prev"
          directionText="Previous"
          onClickHandler={this.props.previous}
        />
        <CarouselControl
          direction="next"
          directionText="Next"
          onClickHandler={this.props.next}
        />
      </Carousel>
    )
  }
}
