import React, { useState } from "react"
import { Container, Row, Col } from "reactstrap"
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { LABELS_LANDING_SERVICES } from "../constants/languages"

import { Modale } from "../elements"

import icon_1 from "../../images/services/icons/ing_suelos.svg"
import icon_2 from "../../images/services/icons/exploracion.svg"
import icon_3 from "../../images/services/icons/ing_ambiental.svg"
import icon_4 from "../../images/services/icons/gestion_riesgos.svg"
import icon_5 from "../../images/services/icons/consultoria.svg"
import icon_6 from "../../images/services/icons/renta_venta.svg"
import imga_1 from "../../images/imgmodels/ingSuelo.jpg"
import imga_2 from "../../images/imgmodels/exploracion_.png"
import imga_3 from "../../images/imgmodels/ingenieria_Ambiental.png"
import imga_4 from "../../images/imgmodels/gestionRiesgos.png"
import imga_5 from "../../images/imgmodels/consultoria_.png"
import imga_6 from "../../images/imgmodels/renta_.png"

export const Services = ({ lang }) => {
  const modelinfo = [
    {
      img: imga_1,
      title: LABELS_LANDING_SERVICES.SOIL_ENGINEERING.title[lang],
      text: LABELS_LANDING_SERVICES.SOIL_ENGINEERING.text[lang],
      link:
        "https://drive.google.com/file/d/1a_9Eiz-e5bKxaTiRVEXjhbmLY6SHf_wR/view?usp=sharing",
    },
    {
      img: imga_2,
      title: LABELS_LANDING_SERVICES.EXPLORATION.title[lang],
      text: LABELS_LANDING_SERVICES.EXPLORATION.text[lang],
      link:
        "https://drive.google.com/file/d/1fBrfgxgX6hBJuSCUfL7ox-s_vZpgngal/view?usp=sharing",
    },
    {
      img: imga_3,
      title: LABELS_LANDING_SERVICES.ENVIRONMENTAL_ENGINEERING.title[lang],
      text: LABELS_LANDING_SERVICES.ENVIRONMENTAL_ENGINEERING.text[lang],
      link:
        "https://drive.google.com/file/d/1OKfbr2xmKtoHkgwuudpdOZi5dJr1KpdR/view?usp=sharing",
    },
    {
      img: imga_4,
      title: LABELS_LANDING_SERVICES.RISK_MANAGEMENT.title[lang],
      text: LABELS_LANDING_SERVICES.RISK_MANAGEMENT.text[lang],
      link:
        "https://drive.google.com/file/d/11TD-y9Hf5D8AAqLMBTrVhvYIwOhpEB3G/view?usp=sharing",
    },
    {
      img: imga_5,
      title: LABELS_LANDING_SERVICES.CONSULTING_TRAINING.title[lang],
      text: LABELS_LANDING_SERVICES.CONSULTING_TRAINING.text[lang],
    },
    {
      img: imga_6,
      title: LABELS_LANDING_SERVICES.EQUIPMENT_RENTAL.title[lang],
      text: LABELS_LANDING_SERVICES.EQUIPMENT_RENTAL.text[lang],
    },
  ]

  const [modal, setModal] = useState(false)
  const [title, setTitle] = useState(modelinfo[0].title)
  const [text, setText] = useState(modelinfo[0].text)
  const [img, setImg] = useState(modelinfo[0].img)
  const [link, setLink] = useState(modelinfo[0].link)
  const toggle = () => setModal(!modal)
  const updateModal = serviceNumber => {
    toggle()
    setSection(
      modelinfo[serviceNumber].title,
      modelinfo[serviceNumber].text,
      modelinfo[serviceNumber].img,
      modelinfo[serviceNumber].link
    )
  }
  const setSection = (title, text, img, link) => {
    setTitle(title)
    setText(text)
    setImg(img)
    setLink(link)
  }

  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col sm={12} md={8}>
          <h2 className="display-4 text_main_color font-weight-bold">
            {LABELS_LANDING_SERVICES.LABEL_SERVICES[lang]}
          </h2>
        </Col>
      </Row>
      <hr className="mt-1 underline_title" />
      <Row className="mt-4 d-flex justify-content-center">
        <Col xs={12} sm={4} md={3} className="text-center">
          <img
            className="modal-img-pointer img-fluid mb-3"
            src={icon_1}
            alt=""
            // eslint-disable-next-line
            onClick={() => updateModal(0)}
          />
          <br />

          <h4 className="contrast">
            {" "}
            {LABELS_LANDING_SERVICES.LABEL_SOIL_ENGINERING[lang]}
          </h4>

          <Modale
            toggle={toggle}
            modal={modal}
            title={title}
            img={img}
            text={text}
            link={link}
          />

          <p className="size_subtitle multiline">
            {LABELS_LANDING_SERVICES.SUB_SOIL[lang]}
          </p>
        </Col>
        <Col xs={12} sm={4} md={3} className="text-center">
          <img
            className="modal-img-pointer img-fluid mb-3"
            src={icon_2}
            alt=""
            // eslint-disable-next-line
            onClick={() => updateModal(1)}
          />
          <br />
          <h4 className="contrast">
            {LABELS_LANDING_SERVICES.LABEL_EXPLORATION[lang]}
          </h4>
          <p className="size_subtitle multiline">
            {LABELS_LANDING_SERVICES.SUB_EXPLORATION[lang]}
          </p>
        </Col>
        <Col xs={12} sm={4} md={3} className="text-center">
          <img
            className="modal-img-pointer img-fluid mb-3"
            src={icon_3}
            alt=""
            // eslint-disable-next-line
            onClick={() => updateModal(2)}
          />
          <br />
          <h4 className="contrast">
            {" "}
            {LABELS_LANDING_SERVICES.LABEL_ENVIROMENTAL[lang]}
          </h4>
          <p className="size_subtitle multiline multiline">
            {LABELS_LANDING_SERVICES.SUB_ENVIROMENTAL[lang]}
          </p>
        </Col>
      </Row>

      <Row className="mt-4 d-flex justify-content-center">
        <Col xs={12} sm={4} md={3} className="text-center">
          <img
            className="modal-img-pointer img-fluid mb-3"
            src={icon_4}
            alt=""
            // eslint-disable-next-line
            onClick={() => updateModal(3)}
          />
          <br />
          <h4 className="contrast">
            {" "}
            {LABELS_LANDING_SERVICES.LABEL_RISKS[lang]}
          </h4>
          <p className="size_subtitle multiline">
            {LABELS_LANDING_SERVICES.SUB_RISKS[lang]}
          </p>
        </Col>
        <Col xs={12} sm={4} md={3} className="text-center">
          <img
            className="modal-img-pointer img-fluid mb-3"
            src={icon_5}
            alt=""
            // eslint-disable-next-line
            onClick={() => updateModal(4)}
          />
          <br />
          <h4 className="contrast">
            {LABELS_LANDING_SERVICES.LABEL_CONSULTING[lang]}
          </h4>
        </Col>
        <Col xs={12} sm={4} md={3} className="text-center">
          <img
            className="modal-img-pointer img-fluid mb-3"
            src={icon_6}
            alt=""
            // eslint-disable-next-line
            onClick={() => updateModal(5)}
          />
          <br />
          <h4 className="contrast">
            {" "}
            {LABELS_LANDING_SERVICES.LABEL_RENTAL[lang]}
          </h4>
          <p className="size_subtitle multiline">
            {LABELS_LANDING_SERVICES.SUB_RENTAL[lang]}
          </p>
        </Col>
      </Row>
    </Container>
  )
}
