import React from "react"
import {
  Card,
  CardBody,
  CardHeader,
  CardText,
  CardFooter,
  Button,
} from "reactstrap"

export const SummaryEl = props => {
  const { title, empresa, tel, email, nombre, apellidos, giro } = props
  return (
    <Card className="text-center mb-3 animated fadeInPlace text-dark">
      <CardHeader>{title}</CardHeader>
      <CardBody>
        <CardText className="animated fadeInPlace">
          <i className="fas fa-user" />
          &nbsp; {nombre.replace(/\s/g, "")}&nbsp;{apellidos.replace(/\s/g, "")}
          <Button onClick={() => props.setPossibleQ(1)} color="link">
            Editar
          </Button>
          <i className="fas fa-building" />
          &nbsp;Empresa:&nbsp;{empresa}
          <Button onClick={() => props.setPossibleQ(3)} color="link">
            Editar
          </Button>
          <hr />
          <i className="fas fa-phone" />
          &nbsp;Teléfono:&nbsp;{tel}
          <Button onClick={() => props.setPossibleQ(5)} color="link">
            Editar
          </Button>
          <i className="fas fa-phone" />
          &nbsp;Giro:&nbsp;{giro}
          <Button onClick={() => props.setPossibleQ(7)} color="link">
            Editar
          </Button>
          <i className="fas fa-phone" />
          &nbsp;País:&nbsp;{giro}
          <Button onClick={() => props.setPossibleQ(6)} color="link">
            Editar
          </Button>
          <i className="fas fa-at" />
          &nbsp;Mail:&nbsp;{email}
          <Button onClick={() => props.setPossibleQ(4)} color="link">
            Editar
          </Button>
          <br />
          {/* <div>
                <Button form="formQuoter" size="lg" className="animated fadeInUp mb-1" color="primary" outline="outline">Confirmar&nbsp;
                  <i className="fas fa-rocket"/></Button>

              </div> */}
        </CardText>
      </CardBody>
      <CardFooter>
        {/* <CardText>
            ¿Duda o comentario?
            <br/>
            <a target="_blank" rel="noopener noreferrer" href="https://wa.me/5212225313392?text=Hola">Cuéntanos por Whatsapp
              <i className="fab fa-whatsapp"/>
            </a>
          </CardText> */}
      </CardFooter>
    </Card>
  )
}
