import React from "react"
import { Container, Row, Col } from "reactstrap"
import { LABELS_US_MEDIA } from "../constants/languages"

import tupper from "../../images/media/tupper.png"
import caja from "../../images/media/caja.png"

export const Media = ({ lang }) => {
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col sm={12} md={8} className="">
          <h2 className="display-4 text_main_color font-weight-bold">
            {LABELS_US_MEDIA.MEDIA[lang]}
          </h2>
        </Col>
      </Row>

      <hr className="mt-1 underline_title" />
      <Row className="mt-4 justify-content-center align-items-center text-center">
        <Col xs={12} sm={4}>
          <a
            href="https://www.facebook.com/eltopermx/videos/356403398558842/UzpfSTY2NTAzOTc0MDM1MTQyNDoxMDY1MjAxNTg3MDAxOTAy/?__tn__=kCH-R&eid=ARBWtXirUeokFgfYsCpIHoXzOw_J2mHzjvgBznNvx9O_AIRwjVrTeo17VajZycwVH4IBXGF8nAdMggYC&hc_ref=ARQHlCeeAECYWQgR5ejU9_fAr8mrD3_cn-kFQWiKhs7w0XZadvd2lOEzcNrf4BMula4&fref=tag&__xts__[0]=68.ARBaKXkYOmYfUGeSfmkx_kXubULL9XEvIY0DxQTbTxLQ2BaIv58u-qs6NIV6kJ920Xqe4PFFmeKwJQCtJ7VDq2Lzb2ItD-fRmi15LxTopNxO4DAcgYUK_ZW628nIw8-m45gEXpdg3GYSsKaXGRSe6H6MXaI-N9uNVC3lkbmLorI2bgZbEyqC2MacAwt777WNZ1YsiSI8kgd928CHmdTrQSZuZ6ub5y2EVad_RaqLJFyFhp1tO8FCbpKIphYAFUCX886pgI_zZzyPX4EvxpuBwY5r9b0IvfS1MSeYyOv9cJ_bGBj_jsaN-bJebbphjOqEPPpJuM4uLZTMEZzuf5_I6jZpSzT7C1jYeHF0HvxP_lY4rDZFvgCZK7IJXAGNriWG4rrR-yUc-l4LufOHC3t0zi6bhkfAy-fCkNVAR-QwGZaRJEkbJ4QhcTTRE0Y"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={tupper} alt="" className="img-fluid" />
          </a>
        </Col>
        <Col xs={12} sm={4}>
          <p className="d-none d-sm-block contrast font-weight-bold">
            <a
              href="https://www.facebook.com/search/top/?q=%20GCG%20Geof%C3%ADsica%20%23Toppermx&epa=SEARCH_BOX"
              target="_blank"
              rel="noopener noreferrer"
            >
              #Sismos
            </a>
            &nbsp;&nbsp;
            <a
              href="https://www.facebook.com/search/top/?q=%20GCG%20Geof%C3%ADsica%20%23Toppermx&epa=SEARCH_BOX"
              target="_blank"
              rel="noopener noreferrer"
            >
              #Deslizamientos
            </a>
            &nbsp;&nbsp;
            <a
              href="https://www.facebook.com/eltopermx/videos/356376428561539/"
              target="_blank"
              rel="noopener noreferrer"
            >
              #Volcanes
            </a>
          </p>
          <p className="d-block d-sm-none contrast font-weight-bold">
            <a
              href="https://www.facebook.com/search/top/?q=%20GCG%20Geof%C3%ADsica%20%23Toppermx&epa=SEARCH_BOX"
              target="_blank"
              rel="noopener noreferrer"
            >
              #Sismos
            </a>
            <br />
            <br />
            <a
              href="https://www.facebook.com/search/top/?q=%20GCG%20Geof%C3%ADsica%20%23Toppermx&epa=SEARCH_BOX"
              target="_blank"
              rel="noopener noreferrer"
            >
              #Deslizamientos
            </a>

            <br />
            <br />
            <a
              href="https://www.facebook.com/eltopermx/videos/356376428561539/"
              target="_blank"
              rel="noopener noreferrer"
            >
              #Volcanes
            </a>
          </p>
        </Col>
        <Col xs={12} sm={4}>
          <a
            href="https://www.facebook.com/watch/?v=1268067340022828"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={caja} alt="" className="img-fluid" />
          </a>
        </Col>
      </Row>
    </Container>
  )
}
