import React, { Component } from "react"
import { Container, Row, Col } from "reactstrap"
import { ContactForm as FormContact } from "../forms"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { LABELS_LANDING_CONTACT } from "../constants/languages"

export default class ContactForm extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    const { lang } = this.props
    return (
      <Container className="">
        <Row className="d-flex justify-content-center">
          <Col xs={12} md={8} className="text-left">
            <h2 className="display-4 text_main_color font-weight-bold">
              {LABELS_LANDING_CONTACT.LABEL_CONTACT[lang]}
            </h2>
          </Col>
        </Row>

        <hr className="underline_title " />

        <Row className="justify-content-center">
          <Col xs={12} md={10} className="text-center">
            <p className="">
              <h4>
                {LABELS_LANDING_CONTACT.LABEL_EXPLORE[lang]}
                <br />
              </h4>
              <FontAwesomeIcon
                icon={["fab", "whatsapp"]}
                size="2x"
                style={{ marginTop: "2vh", marginBottom: "2vh" }}
              />
              <br />
              {LABELS_LANDING_CONTACT.LABEL_EXPERT[lang]}
              <br /> <br />
              +521 2225887494 <br /> +521 2228132009
              <br />
              <a className="linkref" href="mailto:explora@geofisicacg.com ">
                explora@geofisicacg.com
              </a>{" "}
              <br />
              <a className="linkref" href="mailto:proyectos@geofisicacg.com">
                proyectos@geofisicacg.com
              </a>
              <br />
              <FontAwesomeIcon
                icon={["far", "envelope"]}
                size="2x"
                style={{ marginTop: "2vh", marginBottom: "2vh" }}
              />
              <br />
              {LABELS_LANDING_CONTACT.LABEL_TEAM[lang]}
              <br />
              <a className="linkref" href="mailto:talento@geofisicacg.com">
                talento@geofisicacg.com
              </a>{" "}
              <br />
              <FontAwesomeIcon
                icon={["fa", "map-marker-alt"]}
                size="2x"
                style={{ marginTop: "2vh", marginBottom: "2vh" }}
              />
              <br />
              <a
                className="linkref"
                href=" https://g.page/WorkosferaCoworkingLaPaz?share"
                target="_blank"
                rel="noopener noreferrer"
              >
                Calle Cholula #35, Colonia La Paz, <br />
                CP 72160, Puebla, Puebla.
                {LABELS_LANDING_CONTACT.LABEL_PUEBLA[lang]}
              </a>
            </p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center ">
          <Col sm={12} md={7}>
            <FormContact lang={lang} />
          </Col>
        </Row>
      </Container>
    )
  }
}
