import React from "react"
import { Container, Col } from "reactstrap"
import { LABELS_GLOBAL, LABELS_LANDING_INTRO } from "../constants/languages"

export const Intro = ({ lang }) => {
  return (
    <Container className="d-flex justify-content-center my-5">
      {" "}
      <Col sm={12} md={8} className="text-center my-3">
        <p className="gcgintr">
          <b className="contrast">{LABELS_GLOBAL.GCG[lang]}</b>&nbsp;
          {LABELS_LANDING_INTRO.INTRO[lang]}
        </p>
      </Col>
    </Container>
  )
}
