import React, { Component, Fragment } from "react"
import {
  Container,
  Row,
  Col,
  Card,
  CardImg,
  CardFooter,
  Badge,
  // CardFooter,
  // Badge
} from "reactstrap"
import { ModalGallery, CarouselGallery } from "../gallery_els"
import { LABELS_LANDING_GALLERY } from "../constants/languages"
// import {Image} from '../gallery_els';

import modcarr1 from "../../images/gallery/_modcarr1.jpg"
import modcarr2 from "../../images/gallery/_modcarr2.jpg"
import modcarr3 from "../../images/gallery/_modcarr3.jpg"
import modcarr4 from "../../images/gallery/_modcarr4.jpg"
import modcarr5 from "../../images/gallery/_modcarr5.jpg"
import modcarr6 from "../../images/gallery/_modcarr6.jpg"
import modcarr7 from "../../images/gallery/_modcarr7.jpg"
import modcarr8 from "../../images/gallery/_modcarr8.jpg"
import modcarr9 from "../../images/gallery/_modcarr9.jpg"
import modcarr10 from "../../images/gallery/_modcarr10.jpg"
import modcarr11 from "../../images/gallery/_modcarr11.jpg"
import modcarr12 from "../../images/gallery/_modcarr12.jpg"
import modcarr13 from "../../images/gallery/_modcarr13.png"
import modcarr14 from "../../images/gallery/_modcarr14.jpg"
import modcarr15 from "../../images/gallery/_modcarr15.jpg"
import modcarr16 from "../../images/gallery/_modcarr16.jpg"
import modcarr17 from "../../images/gallery/_modcarr17.jpg"
import modcarr18 from "../../images/gallery/_modcarr18.jpg"
import modcarr19 from "../../images/gallery/_modcarr19.jpg"
import modcarr20 from "../../images/gallery/_modcarr20.jpg"
import modcarr21 from "../../images/gallery/_modcarr21.jpg"
import modcarr22 from "../../images/gallery/_modcarr22.jpg"
import modcarr23 from "../../images/gallery/_modcarr23.jpg"
import modcarr24 from "../../images/gallery/_modcarr24.jpg"
import modcarr25 from "../../images/gallery/_modcarr25.jpg"
import modcarr26 from "../../images/gallery/_modcarr26.jpg"
import modcarr27 from "../../images/gallery/_modcarr27.jpg"
import modcarr28 from "../../images/gallery/_modcarr28.jpg"
import modcarr29 from "../../images/gallery/_modcarr29.jpg"
import modcarr30 from "../../images/gallery/_modcarr30.jpg"

import icon_1 from "../../images/services/icons/ing_suelos.svg"
import icon_2 from "../../images/services/icons/exploracion.svg"
import icon_3 from "../../images/services/icons/ing_ambiental.svg"
import icon_4 from "../../images/services/icons/gestion_riesgos.svg"
import icon_5 from "../../images/services/icons/consultoria.svg"

import magneticos from "../../images/services/method_icons/magneticos.svg"
import geoelectricos from "../../images/services/method_icons/electricos.svg"
import electromagneticos from "../../images/services/method_icons/electromagneticos.svg"
import sismicos from "../../images/services/method_icons/sismicos.svg"

import geoquimica from "../../images/services/method_icons/geoquimica.svg"
import percepcionremota from "../../images/services/method_icons/percepcionremota.svg"

import section_1 from "../../images/gallery/section_1.png"
import section_2 from "../../images/gallery/section_2.png"
import section_3 from "../../images/gallery/section_3.jpg"

const items = [
  {
    img: modcarr1,
    icons: [icon_1, geoelectricos, electromagneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_A.caption,
  },
  {
    img: modcarr2,
    icons: [icon_1, geoelectricos, electromagneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_B.caption,
  },
  {
    img: modcarr3,
    icons: [icon_5, icon_4],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_C.caption,
  },
  {
    img: modcarr4,
    icons: [icon_5, icon_4],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_D.caption,
  },
  {
    img: modcarr5,
    icons: [icon_1, geoelectricos, electromagneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_E.caption,
  },
  {
    img: modcarr6,
    icons: [icon_5],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_F.caption,
  },
  {
    img: modcarr7,
    icons: [icon_2, geoelectricos, magneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_G.caption,
  },
  {
    img: modcarr8,
    icons: [icon_2, geoelectricos, magneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_H.caption,
  },
  {
    img: modcarr9,
    icons: [icon_2, geoelectricos, magneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_I.caption,
  },
  {
    img: modcarr10,
    icons: [
      icon_2,
      magneticos,
      electromagneticos,
      geoelectricos,
      percepcionremota,
    ],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_J.caption,
  },
  {
    img: modcarr11,
    icons: [icon_4, icon_1, sismicos, geoelectricos, electromagneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_K.caption,
  },
  {
    img: modcarr12,
    icons: [icon_2, geoelectricos, electromagneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_L.caption,
  },
  {
    img: modcarr13,
    icons: [icon_2, magneticos, electromagneticos, geoelectricos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_M.caption,
  },
  {
    img: modcarr14,
    icons: [magneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_N.caption,
  },
  {
    img: modcarr15,
    icons: [icon_4, geoelectricos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_O.caption,
  },
  {
    img: modcarr16,
    icons: [icon_4, percepcionremota],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_P.caption,
  },
  {
    img: modcarr17,
    icons: [sismicos, icon_1],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_Q.caption,
  },
  {
    img: modcarr18,
    icons: [icon_4, percepcionremota],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_R.caption,
  },
  {
    img: modcarr19,
    icons: [icon_2, geoelectricos, percepcionremota],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_S.caption,
  },
  {
    img: modcarr20,
    icons: [sismicos, icon_5],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_T.caption,
  },
  {
    img: modcarr21,
    icons: [icon_1, geoelectricos, electromagneticos, sismicos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_U.caption,
  },
  {
    img: modcarr22,
    icons: [icon_3, electromagneticos, geoquimica, geoelectricos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_V.caption,
  },
  {
    img: modcarr23,
    icons: [
      icon_2,
      electromagneticos,
      geoelectricos,
      magneticos,
      geoquimica,
      percepcionremota,
    ],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_W.caption,
  },
  {
    img: modcarr24,
    icons: [magneticos, geoelectricos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_X.caption,
  },
  {
    img: modcarr25,
    icons: [icon_1, geoelectricos, electromagneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_Y.caption,
  },
  {
    img: modcarr26,
    icons: [icon_2, magneticos, geoelectricos, percepcionremota],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_Z.caption,
  },
  {
    img: modcarr27,
    icons: [icon_2, magneticos],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_AA.caption,
  },
  {
    img: modcarr28,
    icons: [],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_AB.caption,
  },
  {
    img: modcarr29,
    icons: [magneticos, icon_3],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_AC.caption,
  },
  {
    img: modcarr30,
    icons: [geoelectricos, icon_1, icon_2],
    alt: "",
    caption: LABELS_LANDING_GALLERY.ITEM_AD.caption,
  },
]

export default class Gallery extends Component {
  constructor(props) {
    super(props)
    this.state = {
      activeIndex: 0,
      modal: false,
    }
  }
  onExiting = () => {
    this.animating = true
  }

  onExited = () => {
    this.animating = false
  }

  next = () => {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === items.length - 1
        ? 0
        : this.state.activeIndex + 1
    this.setState({ activeIndex: nextIndex })
  }

  previous = () => {
    if (this.animating) return
    const nextIndex =
      this.state.activeIndex === 0
        ? items.length - 1
        : this.state.activeIndex - 1
    this.setState({ activeIndex: nextIndex })
  }

  goToIndex = newIndex => {
    if (this.animating) return
    this.setState({ activeIndex: newIndex })
  }
  toggle = () => {
    this.setState(prevState => ({
      modal: !prevState.modal,
    }))
  }
  openSet = index => {
    this.toggle()
    this.goToIndex(index)
  }
  render() {
    const { lang } = this.props
    const { activeIndex, modal } = this.state
    return (
      <Fragment>
        <ModalGallery
          toggle={this.toggle}
          activeIndex={activeIndex}
          lang={lang}
          items={items}
          isOpen={modal}
        >
          <CarouselGallery
            goToIndex={this.goToIndex}
            previous={this.previous}
            next={this.next}
            onExited={this.onExited}
            onExiting={this.onExiting}
            activeIndex={activeIndex}
            items={items}
          />
        </ModalGallery>

        <Container className="mt-5">
          <Row className="d-flex justify-content-center">
            <Col sm={12} md={8} classname="mt-5">
              <h2 className="mt-5 display-4 font-weight-bold contrast">
                {LABELS_LANDING_GALLERY.TITLE[lang]}
              </h2>
            </Col>
          </Row>
          <hr className="underline_title" />
          <Row>
            {items.map(({ img, alt, icons }, i) => (
              <Col key={i} xs={6} md={4} lg={2}>
                <Card
                  onClick={() => this.openSet(i)}
                  className="mb-2 gallery_img"
                >
                  <CardImg alt={alt} src={img} />
                  {icons.length ? (
                    <CardFooter
                      style={{ minHeigth: "60px" }}
                      className="d-flex flex-column justify-content-start"
                    >
                      <div className="d-block">
                        {icons.slice(0, 3).map((icon, i) => (
                          <Badge className="mr-1" color="light">
                            <img
                              className="icon_gallery_thumb img-fluid"
                              src={icon}
                              alt="icon process involved"
                            />
                          </Badge>
                        ))}
                      </div>
                      <div className="d-block">
                        {icons.slice(3).map((icon, i) => (
                          <Badge className="mr-1" color="light">
                            <img
                              className="icon_gallery_thumb img-fluid"
                              src={icon}
                              alt="icon process involved"
                            />
                          </Badge>
                        ))}
                      </div>
                    </CardFooter>
                  ) : (
                    ""
                  )}
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
        <Container>
          <Row className="mt-5 mb-2">
            <Col xs={12} sm={8}>
              <img className="img-fluid" src={section_1} alt="Reto Puebla" />
            </Col>
            <Col xs={12} sm={4}>
              <h3 className="font-weight-bold contrast mt-2">Reto Puebla</h3>
              <hr className="underline_titleee" />
              <br />
              <p>{LABELS_LANDING_GALLERY.RETO_PUEBLA[lang]}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={{ size: 12, order: 1 }} sm={{ size: 8, order: 2 }}>
              <img className="img-fluid" src={section_2} alt="" />
            </Col>
            <Col xs={{ size: 12, order: 2 }} sm={{ size: 4, order: 1 }}>
              <h3 className="font-weight-bold contrast mt-2">CEMUVA</h3>
              <hr className="underline_titleee" />
              <br />
              <p>{LABELS_LANDING_GALLERY.CEMUVA[lang]}</p>
            </Col>
          </Row>
          <Row className="mb-2">
            <Col xs={12} sm={8}>
              <img className="img-fluid" src={section_3} alt="CANACINTRA" />
            </Col>
            <Col xs={12} sm={4}>
              <h3 className="font-weight-bold contrast mt-2">
                CANACINTRA Puebla
              </h3>
              <hr className="underline_titleee" />
              <br />
              <p>{LABELS_LANDING_GALLERY.CANACINTRA[lang]}</p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    )
  }
}
