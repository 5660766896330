import React from "react"
import { Container, Row, Col } from "reactstrap"
import { LABELS_CLIENTS } from "../constants/languages"

import icon_1 from "../../images/clients/logos/client_1.png"
import icon_2 from "../../images/clients/logos/client_2.png"
import icon_3 from "../../images/clients/logos/client_3.png"
import icon_4 from "../../images/clients/logos/client_4.png"
import icon_5 from "../../images/clients/logos/client_5.png"
import icon_6 from "../../images/clients/logos/client_6.png"

export const Clients = ({ lang }) => {
  return (
    <Container className="text-center">
      <Row className="mb-5">
        <Col>
          <h5 className="">
            {LABELS_CLIENTS.INTRO[lang]}
            <br />
          </h5>
        </Col>
      </Row>
      <Row>
        <Col>
          <h2 className="display-4 text_main_color text-left font-weight-bold">
            {LABELS_CLIENTS.CLIENTS[lang]}
          </h2>
        </Col>
      </Row>
      <hr className="underline_title" />
      <Row className="justify-content-center">
        <Col
          xs={12}
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <img className="img-fluid mb-3" src={icon_1} alt="" />
          <br />
        </Col>
        <Col
          xs={12}
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <img className="img-fluid mb-3" src={icon_2} alt="" />
          <br />
        </Col>
        <Col
          xs={12}
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <img className="img-fluid mb-3" src={icon_5} alt="" />
        </Col>
      </Row>
      <Row className="justify-content-center mt-4">
        <Col
          xs={12}
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <img className="img-fluid mb-3" src={icon_4} alt="" />
          <br />
        </Col>

        <Col
          xs={12}
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <img className="img-fluid mb-3" src={icon_6} alt="" />
        </Col>
        <Col
          xs={12}
          sm={3}
          className="d-flex align-items-center justify-content-center"
        >
          <img className="img-fluid mb-3" src={icon_3} alt="" />
          <br />
        </Col>
      </Row>
    </Container>
  )
}
