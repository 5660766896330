import React, { Fragment, useState } from "react"
import { Container, Row, Col } from "reactstrap"
import { TimeLineNode } from "../timeline_els"
import "../style/timeline.css"
import node_1994 from "../../images/timeline/1994.svg"
import node_2000 from "../../images/timeline/2000.jpg"
import node_2002 from "../../images/timeline/2002.svg"
import node_2005 from "../../images/timeline/2005.svg"
import node_2012 from "../../images/timeline/2012.svg"
import node_2018 from "../../images/timeline/2018.png"
import node_2019 from "../../images/timeline/2019.png"

import { LABELS_US_TIME_LINE } from "../constants/languages"

export default function Timeline({ lang }) {
  const [text, setText] = useState("")
  const [year, setYear] = useState(1994)
  const nodesList = [
    {
      img: node_1994,
      year: 1994,
      text: LABELS_US_TIME_LINE.YEAR_1994[lang],
    },
    {
      img: node_1994,
      year: 1998,
      text: LABELS_US_TIME_LINE.YEAR_1998[lang],
    },
    {
      img: node_2000,
      year: 2000,
      text: LABELS_US_TIME_LINE.YEAR_2000[lang],
    },
    {
      img: node_2002,
      year: 2002,
      text: LABELS_US_TIME_LINE.YEAR_2002[lang],
    },
    {
      img: node_2005,
      year: 2005,
      text: LABELS_US_TIME_LINE.YEAR_2005[lang],
    },
    {
      img: node_2012,
      year: 2012,
      text: LABELS_US_TIME_LINE.YEAR_2012[lang],
    },
    {
      img: node_1994,
      year: 2017,
      text: LABELS_US_TIME_LINE.YEAR_2017[lang],
    },
    {
      img: node_2018,
      year: 2018,
      text: LABELS_US_TIME_LINE.YEAR_2018[lang],
    },
    {
      img: node_2019,
      year: 2019,
      text: LABELS_US_TIME_LINE.YEAR_2019[lang],
    },
  ]
  return (
    <Fragment>
      <Container fluid>
        <Row>
          <Col>
            <div className="timeline_cont">
              <div className="timeline_line d-flex justify-content-around">
                {nodesList.map((el, i) => (
                  <TimeLineNode
                    setText={setText}
                    setYear={setYear}
                    selYear={year}
                    key={i}
                    info={el}
                  />
                ))}
              </div>
            </div>
          </Col>
        </Row>
      </Container>
      <Container className="mb-5">
        <Row className="mt-5">
          <Col>
            <h2 className="display-4 text_main_color ml-3">{year}</h2>
          </Col>
        </Row>
        <Row className="mt-5 min_20 justify-content-center">
          <Col>
            <h4>{text}</h4>
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}
