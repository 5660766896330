import React, { Component } from "react"
import { Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { LABELS_LANDING_WEARE } from "../constants/languages"

const sections = [
  {
    title: LABELS_LANDING_WEARE.MISSION.title,
    text: LABELS_LANDING_WEARE.MISSION.text,
  },
  {
    title: LABELS_LANDING_WEARE.VISION.title,
    text: LABELS_LANDING_WEARE.VISION.text,
  },
  {
    title: LABELS_LANDING_WEARE.PHYLOSOPHY.title,
    text: LABELS_LANDING_WEARE.PHYLOSOPHY.text,
  },
]

export default class WeAre extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selSection: sections[0].title,
      text: sections[0].text,
    }
  }
  setSection = (section, text) => {
    this.setState({
      selSection: section,
      text,
    })
  }
  render() {
    const { lang } = this.props
    const { text: textG, selSection } = this.state
    return (
      <Container>
        <Row className="d-flex justify-content-center">
          <Col sm={12} md={8}>
            <h2 className="display-4 text_main_color font-weight-bold">
              {LABELS_LANDING_WEARE.US[lang]}
            </h2>
          </Col>
        </Row>
        <hr className="mt-2 underline_title" />
        <Row>
          <Col className="d-flex justify-content-center">
            <Nav justified>
              {sections.map(({ title, text }, i) => (
                <NavItem
                  onFocus={() => this.setSection(title, text)}
                  onMouseOver={() => this.setSection(title, text)}
                  key={i}
                >
                  <NavLink
                    tag="span"
                    active={selSection[lang] === title[lang]}
                    className="link_nav_us"
                  >
                    {title[lang]}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>
        <Row className="min_30 mt-5 d-flex justify-content-center ">
          <Col xs={12} sm={4} md={6} className="multiline text-center w-100">
            {textG[lang]}
          </Col>
        </Row>
      </Container>
    )
  }
}
