import React, { Component } from "react"
import {
  Input,
  FormGroup,
  InputGroup,
  InputGroupAddon,
  Button,
  Progress,
} from "reactstrap"
// import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { fetchStatus } from "./utils"

export default class Subscribe extends Component {
  constructor(props) {
    super(props)
    this.state = {
      progr: 0,
      mail: "",
      isPosting: false,
    }
  }
  componentDidUpdate = (prevProps, prevState) => {
    const { isPosting } = this.state
    const { isPosting: wasSendingMail } = prevState
    if (!wasSendingMail && isPosting) {
      this.setState({
        progr: 100,
      })
    }
  }
  sendInfo = () => {
    const { mail } = this.state
    const data = { correo_interesado: mail }
    const formData = new FormData()
    for (let key in data) {
      data[key] && formData.append(key, data[key])
    }
    // for (let val of formData.entries()){
    // console.log(`ORA!`)
    // console.log(`${val[0]} ${val[1]}`)
    // }
    // console.log(formData.entries())
    if (mail !== "") {
      this.setState({
        isPosting: true,
      })
      fetch("http://geofisicacg.com/php/mail_contact.php", {
        method: "POST",
        mode: "cors",
        headers: new Headers({
          Accept: "application/json, */*",
        }),
        body: formData,
      })
        .then(fetchStatus)
        .then(r => {
          // console.log('Messages',r)
          this.setState({
            isPosting: false,
            apiMsg: "Correo enviado, revise también su bandeja de spam",
            progr: 100,
          })
          setTimeout(() => {
            this.setState({
              sending: false,
            })
          }, 1000)
        })
        .catch(e => console.warn(e))
    } else {
      this.setState({
        apiMsg: "Por favor proporcione un correo ⚠️",
      })
    }
  }
  getValue = e => {
    const {
      target: { value, name },
    } = e
    this.setState({
      [name]: value,
    })
  }
  render() {
    const { isStacked } = this.props
    const { progr, isPosting, apiMsg } = this.state
    return (
      <div>
        {isStacked ? (
          <div>
            {isPosting ? (
              <Progress value={progr} animated />
            ) : (
              <FormGroup>
                <Input
                  onChange={this.getValue}
                  type="email"
                  placeholder="Tu correo"
                  name="mail"
                />
                <Button
                  onClick={this.sendInfo}
                  block
                  className="hvr_top mt-1"
                  color="primary"
                >
                  Suscribirse &nbsp;
                  {/* <FontAwesomeIcon icon="envelope"/> */}
                </Button>
              </FormGroup>
            )}
          </div>
        ) : (
          <div>
            {isPosting ? (
              <Progress value={progr} animated />
            ) : (
              <InputGroup>
                <Input
                  onChange={this.getValue}
                  type="email"
                  placeholder="Tu correo"
                  name="mail"
                />
                <InputGroupAddon addonType="append">
                  <Button
                    onClick={this.sendInfo}
                    className="hvr_top"
                    color="primary"
                  >
                    Suscribirse&nbsp;
                    {/* <FontAwesomeIcon icon="envelope"/> */}
                  </Button>
                </InputGroupAddon>
              </InputGroup>
            )}
          </div>
        )}

        {apiMsg !== "" && (
          <small className="form-text text-muted">
            {apiMsg} <br /> Revise también su bandeja de correo no deseado{" "}
          </small>
        )}
      </div>
    )
  }
}
