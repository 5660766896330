import React, { Component } from "react"
import { Modal, ModalHeader, ModalBody, ModalFooter, Badge } from "reactstrap"

export default class ModalGallery extends Component {
  render() {
    const { activeIndex, items, lang } = this.props
    const { caption = "", icons } = items[activeIndex]
    return (
      <Modal
        size="lg"
        isOpen={this.props.isOpen}
        toggle={this.props.toggle}
        className={this.props.className}
      >
        <ModalHeader toggle={this.props.toggle}></ModalHeader>
        <ModalBody>{this.props.children}</ModalBody>
        <ModalFooter>
          <p className="text-left multiline w-100">{caption[lang]}</p>
          <div className="d-block text-left">
            {icons.length &&
              icons.map((icon, i) => (
                <Badge className="mr-1" color="light">
                  <img
                    className="icon_gallery img-fluid"
                    src={icon}
                    alt="icon process involved"
                  />
                </Badge>
              ))}
          </div>
        </ModalFooter>
      </Modal>
    )
  }
}
