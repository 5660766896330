import React, { Component } from "react"
import { TransitionGroup, CSSTransition } from "react-transition-group"
import { Button, Container, Row, Col, Nav, NavItem, NavLink } from "reactstrap"
import { LABELS_LANDING_METHOD } from "../constants/languages"

import magneticos from "../../images/services/method_icons/magneticos.svg"
import geoelectricos from "../../images/services/method_icons/electricos.svg"
import electromagneticos from "../../images/services/method_icons/electromagneticos.svg"
import sismicos from "../../images/services/method_icons/sismicos.svg"
import radiometrica from "../../images/services/method_icons/radiometrica.svg"
import geoquimica from "../../images/services/method_icons/geoquimica.svg"
import percepcionremota from "../../images/services/method_icons/percepcionremota.svg"
import geofisicos from "../../images/services/method_icons/geofisicos.svg"

const sections = [
  {
    title: LABELS_LANDING_METHOD.MAGNETIC.title,
    text: LABELS_LANDING_METHOD.MAGNETIC.text,
    icon: magneticos,
  },
  {
    title: LABELS_LANDING_METHOD.GEOELECTRIC.title,
    text: LABELS_LANDING_METHOD.GEOELECTRIC.text,
    icon: geoelectricos,
  },
  {
    title: LABELS_LANDING_METHOD.ELECTROMAGNETIC.title,
    text: LABELS_LANDING_METHOD.ELECTROMAGNETIC.text,
    icon: electromagneticos,
  },
  {
    title: LABELS_LANDING_METHOD.SEISMIC.title,
    text: LABELS_LANDING_METHOD.SEISMIC.text,
    icon: sismicos,
  },

  {
    title: LABELS_LANDING_METHOD.RADIOMETRIC.title,
    text: LABELS_LANDING_METHOD.RADIOMETRIC.text,
    icon: radiometrica,
  },
  {
    title: LABELS_LANDING_METHOD.GEOCHEMISTS.title,
    text: LABELS_LANDING_METHOD.GEOCHEMISTS.text,
    icon: geoquimica,
  },
  {
    title: LABELS_LANDING_METHOD.REMOTE.title,
    text: LABELS_LANDING_METHOD.REMOTE.text,
    icon: percepcionremota,
  },
]

export default class Methods extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selSection: sections[0].title,
      text: sections[0].text,
      icon: sections[0].icon,
    }
  }

  setSection = (section, text, icon) => {
    this.setState({
      selSection: section,
      text,
      icon,
    })
  }

  render() {
    const { lang } = this.props
    const { text: textG, selSection, icon: iconG } = this.state

    return (
      <Container className="mt-5 pt-5">
        <Row className="d-flex justify-content-center">
          <Col sm={12} md={8} className="">
            <h2 className="display-4 text_main_color font-weight-bold text-left">
              {LABELS_LANDING_METHOD.LABEL_METHOD[lang]}
            </h2>
          </Col>
        </Row>
        <hr className="underline_title " />
        <Row className="d-flex justify-content-center text-center">
          <Col sm={12} md={8}>
            <p>{LABELS_LANDING_METHOD.LABEL_METHOD_TEXT[lang]}</p>
          </Col>
        </Row>
        <Row className="d-flex justify-content-center">
          <Col sm={12} md={9} className="mt-3 text-center">
            <Nav className="d-flex justify-content-center">
              {sections.map(({ title, text, icon }, i) => (
                <NavItem
                  onClick={() => this.setSection(title, text, icon)}
                  onMouseEnter={() => this.setSection(title, text, icon)}
                  key={i}
                >
                  <NavLink
                    tag="span"
                    active={selSection[lang] === title[lang]}
                    className="mt-3 ml-4 mr-4 link_nav_us"
                  >
                    {title[lang]}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </Col>
        </Row>

        <Row className="d-flex justify-content-center min_30 mt-5 text-center">
          <Col sm={12} md={8} className="">
            <TransitionGroup key={iconG} appear={true} exit={true}>
              <CSSTransition timeout={300} classNames="method_info">
                <div>
                  <img src={iconG} alt="icon" className="img-fluid mb-4" />{" "}
                  <br />
                  <p className="multiline min_30">
                    {textG[lang]} <br />
                    <br />
                    <Button
                      variant="outlined"
                      color="primary"
                      href="mailto:info@geofisicacg.com"
                      className=""
                    >
                      {LABELS_LANDING_METHOD.LABEL_BUTTON_METHOD[lang]}
                    </Button>
                  </p>
                </div>
              </CSSTransition>
            </TransitionGroup>
          </Col>
        </Row>
      </Container>
    )
  }
}
