import React from "react"
import { Card, CardBody, CardGroup, Col } from "reactstrap"

export const CardChoices = ({
  choicesDays,
  info: { name, title },
  getValue,
  value,
}) => {
  return (
    <Col>
      <h3>{title}</h3>
      <br />
      <CardGroup>
        {choicesDays.map(({ text, date }, i) => (
          <Card
            onClick={() =>
              getValue({
                target: { value: { text, date }, type: "", name: name },
              })
            }
            className="selectable text-dark"
            outline={date === value.date ? false : true}
            color="primary"
            key={i}
          >
            <CardBody>{text}</CardBody>
          </Card>
        ))}
      </CardGroup>
    </Col>
  )
}
