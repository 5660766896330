import React from "react"
import { Container, Row, Col } from "reactstrap"
import { LABELS_LANDING_ALLIANCE } from "../constants/languages"

import mobius from "../../images/services/alliances/mobius.png"
//import atoyac from '../../images/services/alliances/atoyac.png';
//import toper from '../../images/services/alliances/toper.png';
import sia from "../../images/services/alliances/sia.png"

export const Alliance = ({ lang }) => {
  return (
    <Container>
      <Row className="d-flex justify-content-center">
        <Col sm={12} md={8}>
          <h2 className="display-4 textmodi text_main_color font-weight-bold">
            {LABELS_LANDING_ALLIANCE.ALLIANCE[lang]}
          </h2>
        </Col>
      </Row>
      <hr className="mt-2 underline_title" />
      <Row className="mb-5 d-flex justify-content-center">
        {/*<Col>
  <img className="img-fluid" src={skytactic} alt=""/>
</Col>
  */}

        <Col sm={12} md={2}>
          <a
            title="Mobius"
            href="https://www.mobius-consultores.com/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="img-fluid" src={mobius} alt="Logo Mobius" />
          </a>
        </Col>

        <Col sm={12} md={2}>
          <a
            title="Sia"
            href="https://sia-sa.com.mx/home/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img className="img-fluid" src={sia} alt=" Logo Sia" />
          </a>
        </Col>
      </Row>
    </Container>
  )
}
